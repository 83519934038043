import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>Top 3 Tampa Locations You Can ONLY Visit by Boat | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Boats tied together at Beer Can Island in Tampa Bay, FL."
                  src="../../images/blog/boats-tied-together-at-beer-can-island-in-florida.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>Top 3 Tampa Locations You Can ONLY Visit by Boat</h1>
                  <p>October 27, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <p>A boat ride through Tampa Bay's stunning mangroves and jumping dolphins is unlike anything else. The region is frequently referred to as the "heart of Florida's gulf coast" and is a popular travel destination for tourists from across the globe. Florida is full of many beautiful destinations, but some of them are only accessible by boat. So, you’ve chartered a boat for the day, but where should you go party? Let’s take a look at some of our favourite Tampa locations:</p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Girls and guys with drinks on a party boat in Tampa Bay, FL."
                          src="../../images/banners/girls-and-guys-with-drinks.jpg"
                        />
                      </div>
                    </div>
                    <h2>1. Beer Can Island</h2>
                    <p>One of the most popular spots to reach by boat is the famous ‘Beer Can Island’, also known as ‘Pine Key’. The island's nickname, "beer can island," refers to the large number of visitors that come there to party and enjoy some beer while taking in a warm Florida sunset. The public is welcome to visit Beer Can Island, and admission is free. The only way to get to the island is by renting a boat so you can go exploring nearby. We recommend at least a 6-hour charter so that you can spend a few hours on the island, taking in all that it has to offer before sailing back in the afternoon, watching the beautiful sunset over the water.</p>
                    <hr />
                    <h2>2. Fantasy Island</h2>
                    <p>Just along the Hillsborough Bay from Beer Can Island is Fantasy Island. This is a small, dredged up island which is perfect for watching the sunset in a more relaxed location compared to the madness and entertainment of Beer Can Island. This island is popular with locals who like to spend time in the Bay but avoid the tourist traps. The island is best visited in the afternoon when you can watch the sunset, basking in the warm rays as they hit warmly off the calm water around you. Paddle in the ocean, have a romantic picnic or simply find a peaceful spot to read a good book. One thing to watch out for is the bugs – with mosquitos and gnats annoying visitors if the wind is blowing in the right direction. To avoid this, visit during calm weather and pack your bug spray just in case!</p>
                    <hr />
                    <h2>3. Sandbar Parties at Weedon Island</h2>
                    <p>Last but certainly not least is Weedon Island, located South West of Tampa. When we say island, we don’t really mean the island itself, but the sandbar which is located in the shallow water around the island. This location is famous for amazing sandbar parties, where many boats pull up and tie themselves together around the sandbar for a great time – on the best days you will see dozens of boats anchored around this area. Party-goers will come together, mix with other boats, set up tables in the shallow water, drink and enjoy the warm Florida climate. This location is truly the ultimate Florida party experience and brilliant for both groups of friends or family gatherings.</p>
                    <hr />
                    <h2>Select Your Party Boat Charter</h2>
                    <h3>See what all's included with our wide variety of party boat charter packages.</h3>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage